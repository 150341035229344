
import ValidatorTable from './ValidatorTable';
// import config from '../../config/config';
import React from 'react';

class ValidatorList extends React.Component {
    state = {
        validators: []
    }
    
    componentDidMount() {
        console.log(process.env.NODE_ENV);
        // console.log(config.api.host);
        // let protocol = 'http://';
        // if (config.api.port === '443') { 
        //     protocol = 'https://';
        // }
        
        
        let connStr = '/api/validators';
        let cStakeString = '/api/current_staking';
        if (process.env.NODE_ENV === 'development') {
            connStr = 'https://topvalidators.app/api/validators';
            cStakeString = 'https://topvalidators.app/api/current_staking';
        }
       
        // let remote = 'https://api.stakewiz.com/validators';
        // local = 'http://localhost:4000/validators'
        // let remote = protocol + '65.109.49.120:4000/validators'
        // let remote = '/api/validators'
        fetch(connStr, {
            method: 'GET',
            headers: {
                'Content-Encoding': 'gzip',                
            }},)
            .then((res) => res.json())
            .then((validatorsData) => {
                //console.log(validatorsData);
                validatorsData.sort((a, b) => (b.apy_estimate === a.apy_estimate ? b.epoch_credits - a.epoch_credits : b.apy_estimate - a.apy_estimate));
                this.setState({ validators: validatorsData });
                //this.props.stateChanger(validatorsData[0].updated_at, validatorsData[0].epoch, validatorsData[0].epoch_slot_height, false);
                return {'updatedAt': validatorsData[0].updated_at, 'epoch': validatorsData[0].epoch, 'slot_height': validatorsData[0].epoch_slot_height, 'loading': false}
                
            })
            .then((bd) => {                 
                fetch(cStakeString)
                    .then((cres) => cres.json())
                    .then((cStakingData) => {
                        this.props.stateChanger(bd.updatedAt, bd.epoch, bd.slot_height, bd.loading, cStakingData[0].total_activating_stake, cStakingData[0].total_deactivating_stake)
                    })

            })            
            .catch((error) => {
                console.log(error)
            });
    }
    
    
    
    render() {
        return (<ValidatorTable validators={this.state.validators} ></ValidatorTable>
        )
    }
    
}

export default ValidatorList