import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import 'font-awesome/css/font-awesome.min.css';
import { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function DisplayWebLink(props) {
    const webSiteAddress = props.webAddress;
    if (webSiteAddress !== '') {
        return (                           
            <a href={webSiteAddress} rel="noreferrer" target="_blank"><i className="fa fa-globe" style={{ marginTop: "0.75rem", marginRight: "0.8rem" }}> </i></a>                                                    
        );        
    }
    return (<div />);
}


function DisplayDelinquent(props) {
    const delinquent = props.delinquent;
 
    if (delinquent) {
        return (
            <OverlayTrigger
                
                placement="right"
                id={['DelinqOver_' + props.id]}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip target={['DelIcon_' + props.id]} id={['Delinq_' + props.id]} {...props}>
                    {props.message}
                </Tooltip>}>                 
                <i className="fa fa-plug text-warning delinquent-sign" id={['DelIcon_' + props.id]} style={{ marginTop: "0.75rem", marginRight: "0.8rem" }}> </i>              
            </OverlayTrigger>
        );
        
    }
    return(<div />);
}

function DisplaySkipRateWarning(props) {
    const skip_rate = props.skip_rate;
    
    if (skip_rate > 75) {
        return (
            <OverlayTrigger
                
                placement="left"
                id={['SkipOver_' + props.id]}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip target={['SkipIcon_' + props.id]} id={['Skip_' + props.id]} {...props}>
                    {props.message}
                </Tooltip>}>                 
                <i className="fa fa-exclamation-triangle text-danger" id={['SkipIcon_' + props.id]} style={{  }}> </i>              
            </OverlayTrigger>
            );
        
        }
    if (skip_rate > 50) {
        return (
            <OverlayTrigger
                
                placement="right"
                id={['SkipOver_' + props.id]}
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip target={['SkipIcon_' + props.id]} id={['Skip_' + props.id]} {...props}>
                    {props.message}
                </Tooltip>}>                 
                <i className="fa fa-exclamation-triangle text-warning" id={['SkipIcon_' + props.id]} style={{ }}> </i>              
            </OverlayTrigger>
            );
        
    }    
    return(<div />);
}

function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

class ValidatorItem extends Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        const popover = (
            <Popover key={['popover_basic-' + this.props.index]} id={['popover-basic-' + this.props.index]} style={{ maxWidth: "95vw" }}>
                <Popover.Header className="d-flex justify-content-between" as="h3">
                    
                    <div><Image img-fluid='true' style={{ height: '40px', width: '40px', borderRadius: '20px' }} src={this.props.validator.image ? this.props.validator.image : "/no-pic.png"} />
                        &nbsp;{this.props.validator.name}                        
                    </div>
                    <div style={{ height: '40px',paddingTop: '10px'}}>Running &nbsp;v{this.props.validator.version}</div>
                </Popover.Header>
                <Popover.Body  style={{ maxWidth: "640px" }}>
                    <p><b>Vote Credits earned this epoch:</b> {this.props.validator.epoch_credits}</p>
                    <p><b>Current Staking APY</b>: {this.props.validator.apy_estimate}% &nbsp;&nbsp;&nbsp;&nbsp;<b>Historical Staking APY</b>: {this.props.validator.staking_apy}% </p>
                    <p><b>Historical Jito APY</b>: {this.props.validator.jito_apy}%</p>
                    <p><b>Description</b>: {this.props.validator.description}</p>
                    <p><b>Stake</b>: ◎&nbsp;{this.props.validator.activated_stake.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}&nbsp;({this.props.validator.stake_weight}%)</p>
                    <p><b>Website</b>: <a href={this.props.validator.website} rel="noreferrer" target="_blank"> {this.props.validator.website}</a></p>
                    <p><b>Country</b>: {this.props.validator.ip_country}   |   <b>City</b>: {this.props.validator.ip_city}   |  <b>ASN</b>: {this.props.validator.ip_org} ({this.props.validator.ip_asn})</p>
                    
                    <p><b>Identity</b>: {this.props.validator.identity}<br />
                    <b>VoteAccount</b>: {this.props.validator.vote_identity}</p>
                    
                    
                </Popover.Body>
            </Popover>
        );
        return (
            <tr key={['valRow-' + this.props.index]}>
                <td key={['indexItem-' + this.props.index]}>
                    <div className='data-card'> {this.props.index} </div>
                </td>
                <td key={['apyItem-' + this.props.index]}>
                    <div className='data-card'> {this.props.validator.apy_estimate}%</div>
                </td>       
                     
                <td key={['nameItem-' + this.props.index]}>                    
                        <OverlayTrigger key={this.props.index} trigger="click" rootClose placement="bottom" position="absolute" overlay={popover}>
                            <Card className='validator-card' id={["valcard_"+this.props.index]}>
                                <div className="d-flex justify-content-between">
                                    <div className='val-name'>
                                        <Image img-fluid='true' style={{ height: '40px', width: '40px', borderRadius: '20px' }} src={this.props.validator.image?this.props.validator.image:"/no-pic.png"} />
                                        &nbsp;{this.props.validator.name === '' ? this.props.validator.identity : this.props.validator.name}
                                    </div>
                                    <div className='val-icons'>
                                        <DisplayDelinquent delinquent={this.props.validator.delinquent} id={['DEL_' + this.props.validator.identity]} message="Delinquent" />
                                        <DisplayWebLink webAddress={this.props.validator.website} id={['WEB_' + this.props.validator.identity]} message="Validator's Website" />
                                    </div>
                                </div>
                            </Card>
                        </OverlayTrigger>                    
                </td>
                <td key={['jitoapyItem-' + this.props.index]}>
                    <div className='data-card'> {this.props.validator.jito_apy}%</div>
                </td>     

                <td key={['netapyItem-' + this.props.index]}>
                    <div className='data-card'> {(this.props.validator.jito_apy + this.props.validator.staking_apy).toFixed(2)}%</div>
                </td>   
                {/* <td key={['apy5Item-' + this.props.index]}>
                    <div className='data-card'> {typeof this.props.validator.apy_5 === 'number' ? this.props.validator.apy_5.toFixed(2): 0.00 }%</div>
                </td> */}
                <td key={['commissionItem-' + this.props.index]}>
                    <div className='data-card commission-column'> {this.props.validator.commission}%</div>
                </td>
                <td key={['stakeItem-' + this.props.index]}>
                    <div className='data-card'> ◎&nbsp;{nFormatter(this.props.validator.activated_stake.toFixed(0), 1)}&nbsp;({this.props.validator.stake_weight}%)</div>
                </td>
                <td key={['chg_stakeItem-' + this.props.index]}>
                    <div className='data-card'> ◎&nbsp;{this.props.validator.activating_stake >=0 && (this.props.validator.activating_stake - this.props.validator.deactivating_stake).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                </td>
                <td key={['skip_rate-' + this.props.index]}>
                    <div className='data-card'> {this.props.validator.skip_rate.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}% &nbsp;{this.props.validator.skip_rate > 50 ?
                        <DisplaySkipRateWarning skip_rate={this.props.validator.skip_rate} id={['SKIP_' + this.props.validator.identity]}
                            message="This validator has a very high block failure rate which isn't good for the cluster." /> : ''}
                    </div>
                </td>
                <td key={['asncity_concentration-' + this.props.index]}>
                    <div className='data-card'> {this.props.validator.asncity_concentration}%</div>
                </td>
            </tr>

               
 
        );
    }    
           
}
export default ValidatorItem;